import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ThemeService } from './shared/services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'frontend-sag';

    constructor(private translate: TranslateService, private theme: ThemeService, private router: Router) {}

    ngOnInit(): void {
        this.routeToRedirect();
        this.translate.setDefaultLang('de');
        this.translate.use('de');
        this.theme.setTheme('dark');
    }

    private routeToRedirect(): void {
        const url = window.localStorage.getItem('redirectUrl');
        if (url) {
            void this.router.navigateByUrl(url);
            window.localStorage.removeItem('redirectUrl');
        }
    }
}
