import { AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { SeasonService } from '../../../services/season.service';
import { map, Observable, of } from 'rxjs';
import { SeasonStatus } from 'interfaces/enums';

export class TimeFrameValidators {
    static dateRangeConflict(seasonService: SeasonService): AsyncValidatorFn {
        return (formGroup: AbstractControl): Observable<ValidationErrors | null> => {
            const _startDate = formGroup.get('startDate')?.value;
            const _endDate = formGroup.get('endDate')?.value;
            const _applicationDeadlineDate = formGroup.get('applicationDeadlineDate')?.value;

            if (!(_startDate && _endDate) || !(_endDate && _applicationDeadlineDate)) {
                return of(null);
            }

            const startDate = new Date(_startDate);
            const endDate = new Date(_endDate);
            const applicationDeadlineDate = new Date(_applicationDeadlineDate);

            if (_endDate && _applicationDeadlineDate && endDate > applicationDeadlineDate) {
                return of({ endDateAfterApplicationDeadline: true });
            }

            return seasonService
                .getSeasons(0, 1, undefined, startDate, endDate, [SeasonStatus.ACTIVE, SeasonStatus.CLOSED])
                .pipe(
                    map(intersectingSeasons => {
                        if (intersectingSeasons.metadata.itemCount > 0) {
                            return { dateRangeConflict: true };
                        } else {
                            return null;
                        }
                    })
                );
        };
    }
}
