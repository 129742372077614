import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, tap } from 'rxjs';
import { GbrUser } from 'interfaces/src';
import { LanguageCode } from 'interfaces/enums';
import { LoadProfilePicture, SetUser, SetUserLanguage } from './user.action';
import { Roles } from '../../shared/enums/roles';
import { UserManagementService } from '../../user-management/services/user-management.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

export interface UserStateModel {
    user?: GbrUser;
    isAuthenticated: boolean;
    allowProfilePicture?: boolean;
    profilePicture?: string;
    language: LanguageCode | undefined;
}

@State<UserStateModel>({
    name: 'userState',
    defaults: {
        user: undefined,
        isAuthenticated: false,
        allowProfilePicture: false,
        profilePicture: undefined,
        language: undefined
    }
})
@Injectable()
export class UserState {
    constructor(
        private userService: UserManagementService,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService
    ) {}

    @Selector()
    static user(state: UserStateModel): GbrUser | undefined {
        return state?.user;
    }

    @Selector()
    static allowProfilePicture(state: UserStateModel): boolean | undefined {
        return state.allowProfilePicture;
    }

    @Selector()
    static profilePicture(state: UserStateModel): string | undefined {
        return state.profilePicture;
    }

    @Selector()
    static isAdmin(state: UserStateModel): boolean | undefined {
        return state?.user.roles?.includes(Roles.ADMIN);
    }

    @Selector()
    static isProcessManager(state: UserStateModel): boolean | undefined {
        return state?.user.roles?.includes(Roles.PROCESS_MANAGER);
    }

    @Selector()
    static userLanguage(state: UserStateModel): LanguageCode {
        return state.language ? state.language : LanguageCode.DE;
    }

    @Action(SetUser)
    public setUser({ patchState }: StateContext<UserStateModel>, { payload }: SetUser) {
        return of(patchState({ user: payload.user, isAuthenticated: payload.isAuthenticated }));
    }

    @Action(LoadProfilePicture)
    public setProfilePicture({ patchState }: StateContext<UserStateModel>) {
        return this.userService.getProfilePicture().pipe(tap(profilePicture => patchState({ profilePicture })));
    }

    @Action(SetUserLanguage)
    public setUserLanguage({ getState, patchState }: StateContext<UserStateModel>, { payload }: SetUserLanguage) {
        const { language: currentLanguage, user } = getState();
        const { gid } = user;
        const { language } = payload;
        this.translate.use(language);
        this.document.documentElement.lang = language;
        patchState({ language });
        if (currentLanguage && currentLanguage !== language) {
            return this.userService.updateUser({ gid, language });
        }
        return of(null);
    }
}
