import { Component, HostListener, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store } from '@ngxs/store';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
    ButtonModule,
    ChipModule,
    DialogModule,
    DialogService,
    InputModule,
    ScdPickerExtendedModule
} from '@siemens/component-lib';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { WorkersCouncilGroupState } from '../../state/workers-council-group.state';
import { SharedModule } from '../../../shared/shared.module';
import { scrollOverAmount } from '../../helpers/tiny-helpers';
import {
    AddDelegate,
    LazyLoadWorkersCouncilGroups,
    LoadDelegateUsers,
    LoadWorkersCouncilGroups,
    RemoveDelegate
} from '../../state/workers-council-group.action';
import { SelectDelegateDialogComponent } from '../../dialogs/select-delegate-dialog/select-delegate-dialog.component';
import { User } from '../../../shared/interfaces/CreateOrUpdateUserPayload';
import { WorkersCouncilGroup } from 'interfaces/app';

@Component({
    selector: 'app-workers-council-group-list',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ButtonModule,
        InputModule,
        DialogModule,
        SharedModule,
        ChipModule,
        ScdPickerExtendedModule
    ],
    templateUrl: './workers-council-group-list.component.html',
    styleUrl: './workers-council-group-list.component.scss'
})
export class WorkersCouncilGroupListComponent implements OnDestroy, OnInit {
    protected readonly groups$: Observable<WorkersCouncilGroup[]> = inject(Store).select(
        WorkersCouncilGroupState.getWorkersCouncilGroups
    );
    protected readonly isLoading: Signal<boolean> = select(WorkersCouncilGroupState.isLoadingWorkersCouncilGroups);
    private readonly allGroupsLoaded: Signal<boolean> = select(WorkersCouncilGroupState.allGroupsLoaded);
    protected groupsSorted = true;

    private destroy$ = new Subject<void>();

    constructor(private readonly dialog: DialogService, private router: Router, private readonly store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(new LoadWorkersCouncilGroups(this.groupsSorted));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: any): void {
        if (!scrollOverAmount(event) || this.isLoading() || this.allGroupsLoaded()) {
            return;
        }
        this.store.dispatch(new LazyLoadWorkersCouncilGroups(this.groupsSorted));
    }

    sortBRUnit(): void {
        this.groupsSorted = !this.groupsSorted;
        this.store.dispatch(new LoadWorkersCouncilGroups(this.groupsSorted));
    }

    public openPickerDialog(id: string, delegates: User[]): void {
        this.dialog.open<User, unknown>(SelectDelegateDialogComponent, {
            data: {
                delegates: delegates.map(delegate => delegate.gid),
                onAdd: (user: User) => this.store.dispatch(new AddDelegate(id, user))
            }
        });
    }

    public onRemoveDelegate(id: string, gid: string): void {
        this.store.dispatch(new RemoveDelegate(id, gid));
    }
}
