import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: number | null | undefined): string | null {
        if (value === null) {
            return null;
        }
        return this.currencyPipe.transform(value, 'EUR', 'symbol', '1.2-2');
    }
}
