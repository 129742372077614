import { SharedModule } from './../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-financial-card',
    standalone: true,
    templateUrl: './financial-card.component.html',
    imports: [CommonModule, SharedModule]
})
export class FinancialCardComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() amount: number;
    @Input() ammountShouldBeGreen: boolean;
    @Input() currency?: boolean;
}
