import { Component, computed, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import {
    ButtonModule,
    CheckboxModule,
    DialogService,
    InputModule,
    MenuModule,
    SpinnerModule,
    TabsModule
} from '@siemens/component-lib';
import { FinancialCardComponent } from '../financial-card/financial-card.component';
import { CommonModule } from '@angular/common';
import { select, Store } from '@ngxs/store';
import { debounceTime, filter, finalize, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { SeasonState } from '../../../state/season.state';
import { TranslateModule } from '@ngx-translate/core';
import {
    AutoSelectForPayout,
    CloseSeason,
    LoadApplications,
    LoadFinancialOverview,
    LoadSeason,
    ReleasePayout
} from '../../../state/season.action';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SeasonApplicationsTableComponent } from '../applications-table/applications-table.component';
import { FinancialOverviewResponse } from '../../../models/season-financial.model';
import { TimeframeCardComponent } from '../timeframe-card/timeframe-card.component';
import { BudgetCardComponent } from '../budget-card/budget-card.component';
import { SocialCriteriaCardComponent } from '../social-criteria-card/social-criteria-card.component';
import { SalaryCardComponent } from '../salary-card/salary-card.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ReleasePayoutDialogComponent } from '../release-payout-dialog/release-payout-dialog.component';
import { SeasonService } from '../../../services/season.service';
import { CloseSeasonDialogComponent } from '../close-season-dialog/close-season-dialog.component';
import { AutoSelectApplicationsDialogComponent } from '../auto-select-applications-dialog/auto-select-applications-dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { Season, UserApplication } from 'interfaces/app';
import { SeasonStatus } from 'interfaces/enums';

@Component({
    selector: 'app-season-details',
    standalone: true,
    templateUrl: './season-details.component.html',
    imports: [
        ButtonModule,
        TabsModule,
        FinancialCardComponent,
        MenuModule,
        CommonModule,
        CheckboxModule,
        InputModule,
        SeasonApplicationsTableComponent,
        TimeframeCardComponent,
        BudgetCardComponent,
        SocialCriteriaCardComponent,
        SalaryCardComponent,
        TranslateModule,
        SpinnerModule,
        ReactiveFormsModule,
        ButtonModule,
        RouterModule
    ]
})
export class SeasonDetailsComponent implements OnInit, OnDestroy {
    public season$: Observable<Season> = inject(Store).select(SeasonState.getSeason);
    public isLoading$: Observable<boolean> = inject(Store).select(SeasonState.isLoadingSeasonDetails);
    public isLoadingApplications$: Observable<boolean> = inject(Store).select(SeasonState.isLoadingApplications);
    public financialOverviewSignal: Signal<FinancialOverviewResponse> = select(SeasonState.getFinancialOverview);
    public applicationsSignal: Signal<UserApplication[]> = select(SeasonState.getApplications);
    public applicationSearchControl = new FormControl('');
    public seasonStatus = SeasonStatus;
    public isDownloading = false;

    public readonly columnNames = [
        'seasons.season-details.table.gid',
        'seasons.season-details.table.name',
        'seasons.season-details.table.application-status',
        'seasons.season-details.table.monthly-salary',
        'seasons.season-details.table.children',
        'seasons.season-details.table.spouse',
        'seasons.season-details.table.last-year-payout',
        'seasons.season-details.table.evaluation-points',
        'seasons.season-details.table.potential-payout',
        'seasons.season-details.table.comment',
        'seasons.season-details.table.selected-for-payout'
    ];

    protected readonly seasonStateBackgroundColor = {
        [SeasonStatus.ACTIVE]: '#ffffff',
        [SeasonStatus.DRAFT]: '#FFD732',
        [SeasonStatus.CLOSED]: '#9999A9'
    };

    protected isApplicationsSelectedForPayoutSignal: Signal<boolean> = computed(() => {
        const applications = this.applicationsSignal();
        return applications?.some(application => application.isSelectedForPayout);
    });

    protected isBudgetAvailableSignal: Signal<boolean> = computed(() => {
        const financialOverview = this.financialOverviewSignal();
        return financialOverview?.availableBudget?.getAmount() > 0;
    });

    protected hasSeasonApplicationPeriodEndedSignal: Signal<boolean> = computed(() => {
        const season = this.seasonSignal();
        const currentDate = new Date();
        return new Date(season.applicationDeadline) <= currentDate;
    });

    protected isSeasonClosedSignal: Signal<boolean> = computed(() => {
        const season = this.seasonSignal();
        return season.status === SeasonStatus.CLOSED;
    });

    protected isAutoSelectDisabledSignal: Signal<boolean> = computed(() => {
        const season = this.seasonSignal();
        const financialOverview = this.financialOverviewSignal();
        return season.status === SeasonStatus.CLOSED || financialOverview?.totalApplications === 0;
    });

    private destroy$ = new Subject<void>();
    private seasonId: string;
    private seasonSignal: Signal<Season | null> = toSignal(this.season$);

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private dialog: DialogService,
        private router: Router,
        private seasonService: SeasonService
    ) {}

    ngOnInit(): void {
        this.loadData();
        this.initApplicationSearch();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public loadData(): void {
        this.route.params
            .pipe(
                map(params => params['seasonId']),
                tap(seasonId => {
                    this.store.dispatch(new LoadSeason({ seasonId: seasonId }));
                    this.store.dispatch(new LoadFinancialOverview({ seasonId: seasonId }));
                    this.store.dispatch(new LoadApplications({ seasonId: seasonId }));
                    this.seasonId = seasonId;
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    public autoSelectApplications(): void {
        const dialogRef = this.dialog.open(AutoSelectApplicationsDialogComponent, {});

        dialogRef.afterClosed
            .pipe(
                filter((result): result is { applicationCutOffDate: Date; percentage: number } => !!result),
                switchMap(({ applicationCutOffDate, percentage }) =>
                    this.store.dispatch(
                        new AutoSelectForPayout({
                            applicationCutOffDate,
                            percentage
                        })
                    )
                ),
                tap(() => this.loadData())
            )
            .subscribe();
    }

    public releasePayout(): void {
        const dialogRef = this.dialog.open(ReleasePayoutDialogComponent, {});

        dialogRef.afterClosed
            .pipe(
                filter((result): result is { payoutCutOffDate: Date } => !!result),
                switchMap(({ payoutCutOffDate }) =>
                    this.store.dispatch(
                        new ReleasePayout({
                            payoutCutOffDate
                        })
                    )
                ),
                tap(() => this.router.navigate(['payout-success']))
            )
            .subscribe();
    }

    public closeSeason(): void {
        const dialogRef = this.dialog.open(CloseSeasonDialogComponent, {});

        dialogRef.afterClosed
            .pipe(
                takeUntil(this.destroy$),
                filter(confirmed => !!confirmed),
                switchMap(() => this.store.dispatch(new CloseSeason(this.seasonId)))
            )
            .subscribe();
    }

    public downloadPayoutFiles(): void {
        this.isDownloading = true;
        this.season$
            .pipe(
                switchMap(season =>
                    this.seasonService.downloadPayoutFiles(season.id, `Payout_Files_${season.title}.xlsx`)
                ),
                finalize(() => (this.isDownloading = false))
            )
            .subscribe();
    }

    private initApplicationSearch(): void {
        this.applicationSearchControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(300),
                tap(searchTerm => {
                    this.store.dispatch(new LoadApplications({ gid: searchTerm, seasonId: this.seasonId }));
                })
            )
            .subscribe();
    }
}
