import { Action, Selector, State, StateContext } from '@ngxs/store';
import { WorkersCouncilGroupService } from '../services/workers-council-group.service';
import {
    AddDelegate,
    LazyLoadWorkersCouncilGroups,
    LoadDelegateUsers,
    LoadWorkersCouncilGroups,
    RemoveDelegate
} from './workers-council-group.action';
import { Injectable } from '@angular/core';
import { catchError, of, tap } from 'rxjs';
import { WorkersCouncilGroupOperations } from '../operations/wokers-council-group.operations';
import { UserManagementService } from '../../user-management/services/user-management.service';
import { WorkersCouncilGroup, Metadata, User } from 'interfaces/app';

export interface WorkersCouncilGroupStateModel {
    groups: WorkersCouncilGroup[];
    isLoadingWorkersCouncilGroups: boolean;
    metadata: Metadata | undefined;
    users: User[];
    hasLoadedAllDelegatedUsers: boolean;
}

const PAGE_SIZE = 20;

@State<WorkersCouncilGroupStateModel>({
    name: 'WorkersCouncilGroup',
    defaults: {
        groups: [],
        isLoadingWorkersCouncilGroups: false,
        metadata: undefined,
        users: [],
        hasLoadedAllDelegatedUsers: false
    }
})
@Injectable()
export class WorkersCouncilGroupState {
    constructor(
        private workersCouncilGroupService: WorkersCouncilGroupService,
        private userService: UserManagementService
    ) {}

    @Selector()
    static getWorkersCouncilGroups(state: WorkersCouncilGroupStateModel): WorkersCouncilGroup[] {
        return state.groups;
    }

    @Selector()
    static getWorkersCouncilMembersOptions(state: WorkersCouncilGroupStateModel): { label: string; value: User }[] {
        return state.users
            .map(user => ({
                label: `${user.firstName} ${user.lastName} (${user.gid})`,
                value: user
            }))
            .sort((user1, user2) => user1.label.localeCompare(user2.label));
    }

    @Selector()
    static isLoadingWorkersCouncilGroups(state: WorkersCouncilGroupStateModel): boolean {
        return state.isLoadingWorkersCouncilGroups;
    }

    @Selector()
    static allGroupsLoaded(state: WorkersCouncilGroupStateModel): boolean {
        return !state.metadata?.hasNextPage;
    }

    @Selector()
    static getDelegateUsers(state: WorkersCouncilGroupStateModel): User[] {
        return state.users;
    }

    @Action(LoadWorkersCouncilGroups)
    loadWorkersCouncilGroups(ctx: StateContext<WorkersCouncilGroupStateModel>, { sorted }: LoadWorkersCouncilGroups) {
        ctx.patchState({ isLoadingWorkersCouncilGroups: true });
        return this.workersCouncilGroupService.getWorkersCouncilGroups(0, PAGE_SIZE).pipe(
            catchError(() => {
                ctx.patchState({ isLoadingWorkersCouncilGroups: false });
                return of(void 0);
            }),
            tap(response =>
                ctx.patchState({
                    groups: sorted
                        ? response.items.flat()
                        : response.items.flat().sort((w1, w2) => w1.name.localeCompare(w2.name)),
                    isLoadingWorkersCouncilGroups: false,
                    metadata: response.metadata
                })
            )
        );
    }

    @Action(LazyLoadWorkersCouncilGroups)
    lazyLoadWorkersCouncilGroups(
        ctx: StateContext<WorkersCouncilGroupStateModel>,
        { sorted }: LazyLoadWorkersCouncilGroups
    ) {
        const state = ctx.getState();
        const page = state.groups.length / PAGE_SIZE;
        ctx.patchState({ isLoadingWorkersCouncilGroups: true });
        return this.workersCouncilGroupService.getWorkersCouncilGroups(page, PAGE_SIZE).pipe(
            catchError(() => {
                ctx.patchState({ isLoadingWorkersCouncilGroups: false });
                return of(void 0);
            }),
            tap(response =>
                ctx.patchState({
                    groups: sorted
                        ? state.groups.concat(response.items.flat()).sort((w1, w2) => w1.name.localeCompare(w2.name))
                        : state.groups.concat(response.items.flat()),
                    isLoadingWorkersCouncilGroups: false,
                    metadata: response.metadata
                })
            )
        );
    }

    @Action(AddDelegate)
    addDelegate(ctx: StateContext<WorkersCouncilGroupStateModel>, action: AddDelegate) {
        const state = ctx.getState();

        const { groups, delegateGIDs } = WorkersCouncilGroupOperations.addDelegate(
            action.groupId,
            state.groups,
            action.user
        );

        ctx.patchState({ groups });

        return this.workersCouncilGroupService.updateWorkersCouncilGroup(action.groupId, { delegateGIDs });
    }

    @Action(RemoveDelegate)
    removeDelegate(ctx: StateContext<WorkersCouncilGroupStateModel>, action: RemoveDelegate) {
        const state = ctx.getState();

        const { groups, delegateGIDs } = WorkersCouncilGroupOperations.removeDelegate(
            action.groupId,
            state.groups,
            action.gid
        );

        ctx.patchState({ groups });

        return this.workersCouncilGroupService.updateWorkersCouncilGroup(action.groupId, { delegateGIDs });
    }

    @Action(LoadDelegateUsers)
    loadDelegateUsers(context: StateContext<WorkersCouncilGroupStateModel>, { search, page }: LoadDelegateUsers) {
        if (context.getState().hasLoadedAllDelegatedUsers && search === '') {
            return null;
        }

        return this.userService.getDelegateUsers(search, page).pipe(
            tap(({ items, metadata }) =>
                context.patchState({
                    hasLoadedAllDelegatedUsers: search === '' ? !metadata.hasNextPage : false,
                    users: page === 0 ? items : [...context.getState().users, ...items]
                })
            )
        );
    }
}
