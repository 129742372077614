import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { canActivateAdmin } from './auth/guards/admin-guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SeasonCreateEditComponent } from './season/components/season-create-edit/season-create-edit/season-create-edit.component';
import { UserManagementWrapperComponent } from './user-management/user-management.component';
import { SeasonSuccessComponent } from './season/components/season-create-edit/season-success/season-success.component';
import { SeasonsOverviewComponent } from './season/components/season-list/seasons-overview/seasons-overview.component';
import { SeasonDetailsComponent } from './season/components/season-details/season-details/season-details.component';
import { canActivateProcessManager } from './auth/guards/process-manager-guard';
import { WorkersCouncilGroupListComponent } from './workers-council-group/components/workers-council-group-list/workers-council-group-list.component';
import { PayoutSuccessComponent } from './season/components/season-details/payout-success/payout-success.component';
import { seasonStatusGuard } from './season/guards/season-status.guard';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateProcessManager],
        pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthGmsRoutingModule)
    },
    {
        path: 'seasons',
        component: SeasonsOverviewComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateProcessManager]
    },
    {
        path: 'season/success',
        component: SeasonSuccessComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateAdmin]
    },
    {
        path: 'season/create',
        component: SeasonCreateEditComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateAdmin]
    },
    {
        path: 'season/:seasonId',
        component: SeasonCreateEditComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateAdmin, seasonStatusGuard]
    },
    {
        path: 'user-management',
        component: UserManagementWrapperComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateAdmin]
    },
    {
        path: 'translations',
        loadChildren: () => import('./translations/translations-routing.module').then(m => m.TranslationsRoutingModule),
        canActivate: [AutoLoginPartialRoutesGuard, canActivateAdmin]
    },
    {
        path: 'workers-council-groups',
        component: WorkersCouncilGroupListComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateAdmin]
    },
    {
        path: 'season-details/:seasonId',
        component: SeasonDetailsComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateProcessManager]
    },
    {
        path: 'payout-success',
        component: PayoutSuccessComponent,
        canActivate: [AutoLoginPartialRoutesGuard, canActivateProcessManager]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
