<div class="flex items-center header-wrapper pt-2 border-b border-customBorder">
    <!-- top part -->
    <div>
        <div class="nav-part start cursor-pointer" [routerLink]="['/']">
            <div class="logo"><img [src]="'assets/images/sag-logo/sie-logo-white-rgb.svg'" alt="Siemens logo" /></div>
            <div class="logo"><img [src]="'assets/images/next-siemens-logo.svg'" alt="Next Siemens logo" /></div>
            <div class="app-name">GBR EMA Cloud</div>
        </div>
        <div class="nav-part end hide-mobile">
            <div class="nav-icon" [matMenuTriggerFor]="langMenu"><span class="icon-region"></span></div>
            <mat-menu #langMenu="matMenu">
                <button (click)="changeLang('en')" mat-menu-item>English</button>
                <button (click)="changeLang('de')" mat-menu-item>Deutsch</button>
            </mat-menu>

            <app-user class="hide-mobile"></app-user>
        </div>
    </div>
</div>
