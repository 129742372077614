<div class="p-6 pt-4 max-w-[400px]" [formGroup]="releasePayoutFormGroup">
    <h3 class="text-xl mb-2">{{ 'seasons.season-details.dialogs.release-payout-dialog.title' | translate }}</h3>
    <p class="text-base mb-4">{{ 'seasons.season-details.dialogs.release-payout-dialog.description' | translate }}</p>
    <sag-ui-input
        type="date"
        formControlName="payoutCutOffDate"
        [label]="
            'seasons.season-overview.dialogs.release-payout-dialog.payout-cut-off-date' | translate
        "
        [placeholder]="
            'seasons.season-overview.dialogs.release-payout-dialog.datepicker-placeholder' | translate
        "
        [required]="true"
        [error]="payoutCutOffDateErrorSignal() | translate"
    ></sag-ui-input>
    <sag-ui-input
        label="{{ 'seasons.season-details.dialogs.release-payout-dialog.confirm-label' | translate }}"
        formControlName="confirmPayout"
        [required]="true"
        [error]="confirmPayoutErrorSignal() | translate"
    ></sag-ui-input>
    <div class="flex justify-end items-center gap-2 mt-8">
        <sag-ui-button color="secondary" icon="chevron-left" (click)="onClose()">
            {{ 'seasons.season-details.dialogs.release-payout-dialog.cancel' | translate }}
        </sag-ui-button>
        <sag-ui-button icon="chevron-right" (click)="onClose('confirm')" [disabled]="!releasePayoutFormGroup.valid">
            {{ 'seasons.season-details.dialogs.release-payout-dialog.release-payout' | translate }}
        </sag-ui-button>
    </div>
</div>
