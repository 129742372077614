/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'packages/frontend-admin/src/environments/environment';
import { HttpHelperService } from '../../shared/services/http-helper.service';
import { UserApplication } from 'interfaces/app';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    constructor(private http: HttpClient, private httpHelpersService: HttpHelperService) {}

    public selectPayout(id: string): Observable<UserApplication> {
        return this.http
            .patch<UserApplication>(`${environment.apiUrl}/v1/applications/${id}/select-payout`, {})
            .pipe(this.httpHelpersService.handleError());
    }

    public unselectPayout(id: string): Observable<UserApplication> {
        return this.http
            .patch<UserApplication>(`${environment.apiUrl}/v1/applications/${id}/unselect-payout`, {})
            .pipe(this.httpHelpersService.handleError());
    }

    public updateComment(id: string, comment: string): Observable<UserApplication> {
        return this.http
            .patch<UserApplication>(`${environment.apiUrl}/v1/applications/${id}/comment`, { comment })
            .pipe(this.httpHelpersService.handleError());
    }

    public deleteComment(id: string): Observable<UserApplication> {
        return this.http
            .delete<UserApplication>(`${environment.apiUrl}/v1/applications/${id}/comment`)
            .pipe(this.httpHelpersService.handleError());
    }
}
