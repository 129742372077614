import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef, RemoteAutocompleteModule } from '@siemens/component-lib';
import { SharedModule } from '../../../shared/shared.module';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { WorkersCouncilGroupState } from '../../state/workers-council-group.state';
import { User } from 'interfaces/app';
import { LoadDelegateUsers } from '../../state/workers-council-group.action';

@Component({
    selector: 'app-select-delegate-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule, RemoteAutocompleteModule],
    templateUrl: './select-delegate-dialog.component.html'
})
export class SelectDelegateDialogComponent {
    protected readonly workersCouncilUsers$ = inject(Store).select(
        WorkersCouncilGroupState.getWorkersCouncilMembersOptions
    );

    protected isLoading = false;
    protected selectControl = new FormControl({ value: null, disabled: this.isLoading });

    public compareFn = (firstUser: User, secondUser: User) =>
        firstUser && secondUser && firstUser.gid === secondUser.gid;

    constructor(
        private store: Store,
        public dialogRef: DialogRef<User>,
        public config: DialogConfig<{ onAdd: (user: User) => void }>
    ) {}

    public searchFn(search: string, page: number): Observable<void> {
        return this.store.dispatch(new LoadDelegateUsers(search, Math.max(0, page - 1)));
    }

    public addSelectedUser(): void {
        this.config.data.onAdd(this.selectControl.value);
    }
}
