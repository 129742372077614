export function removeNullishAttributes<T extends object>(obj: T): Partial<T> {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    const cleanedObj: Partial<T> = { ...obj };
    (Object.keys(cleanedObj) as (keyof T)[]).forEach(key => {
        if (cleanedObj[key] === null || cleanedObj[key] === undefined) {
            delete cleanedObj[key];
        }
    });

    return cleanedObj;
}
