<div class="addUser" *ngIf="Roles | keyvalue as roles">
    <h2 mat-dialog-title>{{ 'user-management.dialogs.add-dialog.title' | translate }}</h2>
    <mat-dialog-content>
        <form [formGroup]="addUserForm">
            <div class="row">
                <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 12rem">
                    <sag-ui-input
                        [required]="true"
                        label="GID"
                        formControlName="gid"
                        (input)="getUserInfoSCD('gid')"
                    ></sag-ui-input>
                </div>
                <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 14rem">
                    <sag-ui-input
                        [required]="true"
                        label="{{ 'user-management.dialogs.add-dialog.first-name' | translate }}"
                        formControlName="firstName"
                    ></sag-ui-input>
                </div>
                <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 14rem">
                    <sag-ui-input
                        [required]="true"
                        label="{{ 'user-management.dialogs.add-dialog.last-name' | translate }}"
                        formControlName="lastName"
                    ></sag-ui-input>
                </div>
            </div>

            <div class="row">
                <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 12rem">
                    <sag-ui-input
                        [required]="true"
                        label="{{ 'user-management.dialogs.add-dialog.email' | translate }}"
                        formControlName="email"
                        (input)="getUserInfoSCD('email')"
                    ></sag-ui-input>
                </div>
                <div style="display: flex; flex-direction: column; width: 14rem">
                    <sag-ui-select
                        matSelect
                        formControlName="roles"
                        label="{{ 'user-management.dialogs.edit-dialog.roles' | translate }}"
                        [multiple]="true"
                    >
                        <sag-ui-option matOption *ngFor="let role of roles" [value]="role.value">{{
                            role.value
                        }}</sag-ui-option>
                    </sag-ui-select>
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <sag-ui-button color="secondary" (click)="cancel()" ariaLabel="Add user">
            {{ 'user-management.dialogs.add-dialog.cancel' | translate }}
        </sag-ui-button>

        <sag-ui-button [disabled]="!addUserForm.valid" (click)="createUser($event)" color="primary" ariaLabel="Create User">
            <span class="icon icon-edit"></span>
            {{ 'user-management.dialogs.add-dialog.confirm' | translate }}
        </sag-ui-button>
    </mat-dialog-actions>
</div>
