import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { provideHttpClient } from '@angular/common/http';
import { UserManagementComponent } from './user-management.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { AddUserComponent } from './dialogs/add-user/add-user.component';
import { EditUserComponent } from './dialogs/edit-user/edit-user.component';
import { DeleteUserComponent } from './dialogs/delete-user/delete-user.component';
import { IOptions } from './types/options.type';
import { TableComponent } from './components/table/table.component';
import { TableComponentWrapperComponent } from './components/table/table-component-wrapper';
import { TableButtonComponent } from './components/table-button/table-button.component';
import { AutocompleteMultiSelectComponent } from './components/table/autocomplete-multi-select/autocomplete-multi-select.component';
import { FilterMenuComponent } from './components/table/filter-menu/filter-menu.component';
import {
    ButtonModule,
    CoreModule,
    InputModule,
    SelectModule,
    SpinnerModule,
    TableModule
} from '@siemens/component-lib';
import { IconComponent } from './components/icon/icon.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

const modules = [
    MatPaginatorModule,
    RouterModule,
    MatMenuModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatIconModule,
    MatTableModule,
    CommonModule,
    MatAutocompleteModule,
    MatChipsModule,
    CoreModule.forRoot('sag'),
    ButtonModule,
    TableModule,
    InputModule,
    SelectModule,
    TranslateModule,
    SpinnerModule,
    ButtonModule
];

@NgModule({
    declarations: [
        UserManagementComponent,
        SnackbarComponent,
        AddUserComponent,
        EditUserComponent,
        DeleteUserComponent,
        TableComponent,
        TableComponentWrapperComponent,
        TableButtonComponent,
        AutocompleteMultiSelectComponent,
        FilterMenuComponent,
        IconComponent
    ],
    imports: [...modules, ButtonModule],
    exports: [UserManagementComponent, SnackbarComponent, AddUserComponent, EditUserComponent, DeleteUserComponent]
})
export class UserManagementModule {
    /**
     * @param {IOptions} options
     * @param {Array<string>} options.resources - array of resources in your project in case of role management - else pass []
     * @param {boolean} options.useScd - whether SCD autofill should be used -- requires SCD configuration on backend and
     * expects an Injectable Angular service with class name SCDService and functions:
     * - getUserByGid(gid: string): Observable<any>
     * - getUserByEmail(gid: string): Observable<any>
     * @param options.apiUrl - full apiUrl for your SCD Get endpoint
     * @param options.useThemes - whether to use themes provided in
     */
    static forRoot({
        resources,
        useScd = true,
        apiUrl,
        useThemes = false
    }: IOptions): ModuleWithProviders<UserManagementModule> {
        const providers = [
            {
                provide: 'RESOURCES',
                useValue: resources
            },
            {
                provide: 'useScd',
                useValue: useScd
            },
            {
                provide: 'API_URL',
                useValue: apiUrl
            },
            {
                provide: 'USE_LIB_THEMES',
                useValue: useThemes
            },
            provideHttpClient()
        ];

        return {
            providers,
            ngModule: UserManagementModule
        };
    }
}
