import { DateTime } from 'luxon';

export const defaultTimezone = 'Europe/Berlin';

export function startOfDay(date: Date, timezone = defaultTimezone): Date {
    return DateTime.fromJSDate(date).setZone(timezone).startOf('day').toJSDate();
}

export function endOfDay(date: Date, timezone = defaultTimezone): Date {
    return DateTime.fromJSDate(date).setZone(timezone).endOf('day').toJSDate();
}
