import { APP_INITIALIZER, ApplicationRef, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { filter, Observable, tap } from 'rxjs';
import { AuthGmsAngularClientModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { UserState } from '../app/auth/state/user.state';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/services/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserManagementWrapperModule } from './user-management/user-management.module';
import { TranslationsModule } from './translations/translations.module';
import { environment } from '../environments/environment';
import { UsersState } from './user-management/state/users.state';
import { SeasonState } from './season/state/season.state';
import { WorkersCouncilGroupState } from './workers-council-group/state/workers-council-group.state';
import { DialogModule } from '@siemens/component-lib';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    const fileLoc = encodeURIComponent('admin/');
    return new TranslateHttpLoader(http, `${environment.translationsUrl}/translations/${fileLoc}`, '.json');
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        UserManagementWrapperModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        DashboardModule,
        DialogModule,
        NgxsModule.forRoot([UserState, SeasonState, UsersState, WorkersCouncilGroupState]),
        NgxsFormPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        TranslationsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AuthGmsAngularClientModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule implements DoBootstrap {
    constructor(private authService: AuthService) {}

    public ngDoBootstrap(appRef: ApplicationRef): void {
        this.authService.isAuthenticated$
            .pipe(
                filter(auth => !!auth),
                tap(() => appRef.bootstrap(AppComponent))
            )
            .subscribe();
    }
}

function initializeAppFactory(authService: AuthService): () => Observable<boolean> {
    return () =>
        authService.isAuthenticated$.pipe(
            filter(auth => !auth),
            tap(() => authService.login())
        );
}
