export const paramsSerializer = (params: Record<string, unknown>): string => {
    const queryParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
        const value = params[key];
        if (Array.isArray(value)) {
            if (value.length) {
                queryParams.append(key, value.join(','));
            }
        } else if (value != null && value !== '') {
            queryParams.append(key, value as string);
        }
    });
    return queryParams.toString();
};
