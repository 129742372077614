import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, filter, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs';
import { TableButtonComponent } from './components/table-button/table-button.component';
import { TableFilterState } from './components/table/table.component';
import { User } from 'interfaces/src';
import { AddUserComponent } from './dialogs/add-user/add-user.component';
import { DeleteUserComponent, DeleteUserDialogData } from './dialogs/delete-user/delete-user.component';
import { EditUserComponent } from './dialogs/edit-user/edit-user.component';
import { columns } from './config';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements AfterViewInit {
    @Input() users: User[];
    @Input() total: number;
    @Input() isLoading: boolean;
    @Input() pageSizeOptions = [10, 25, 50, 100];
    @Input() page = 1;

    @Output() searchChanged = new EventEmitter();
    @Output() userAdded = new EventEmitter<User>();
    @Output() userEdited = new EventEmitter<User>();
    @Output() userDeleted = new EventEmitter<string>();
    @Output() pageChange = new EventEmitter<{ page: number; pageSize: number }>();

    protected columns = columns.concat(
        {
            name: '',
            selector: 'edit',
            component: TableButtonComponent,
            componentInit: (row, instance) => {
                instance.text = 'user-management.buttons.edit';
                instance.translate = true;
                instance.type = 'primary';
                instance.buttonClick.pipe(switchMap(() => this.openEditUserDialog(row))).subscribe();
            },
            width: '60px',
            unpadded: true
        },
        {
            name: '',
            selector: 'delete',
            component: TableButtonComponent,
            componentInit: (row, instance) => {
                instance.text = 'user-management.buttons.delete';
                instance.translate = true;
                instance.type = 'secondary';
                instance.buttonClick.pipe(switchMap(() => this.openDeleteUserDialog(row))).subscribe();
            },
            width: '60px',
            unpadded: true
        }
    );

    searchControl: FormControl = new FormControl('');

    constructor(private dialog: MatDialog) {}

    ngAfterViewInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(searchValue => {
                    this.searchChanged.emit(searchValue);
                })
            )
            .subscribe();
    }

    goToCosmos(): void {
        window.open('https://cosmos.siemens-healthineers.com/', '_blank');
    }

    onPageChange(event: { page: number; size: number; filters: TableFilterState }): void {
        const { page, size: pageSize } = event;
        this.pageChange.emit({ page, pageSize });
    }

    openAddUserDialog(event: Event): void {
        event.stopPropagation();
        const dialogRef = this.dialog.open(AddUserComponent, {
            width: '500px',
            height: '420px'
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(({ confirmed }) => confirmed),
                tap(({ payload }) => this.userAdded.emit(payload))
            )
            .subscribe();
    }

    openEditUserDialog(user: User) {
        const dialogRef = this.dialog.open(EditUserComponent, {
            data: user,
            width: '500px',
            height: '420px'
        });

        return dialogRef.afterClosed().pipe(
            filter(({ confirmed }) => confirmed),
            tap(({ payload }) => this.userEdited.emit(payload))
        );
    }

    openDeleteUserDialog({ gid, firstName, lastName }: User) {
        const data: DeleteUserDialogData = { gid, firstName, lastName };
        return this.dialog
            .open(DeleteUserComponent, {
                data,
                width: '500px',
                height: '185px'
            })
            .afterClosed()
            .pipe(
                filter(confirmed => confirmed),
                tap(() => {
                    this.userDeleted.emit(gid);
                })
            );
    }
}
