import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../../../shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-release-payout-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
    templateUrl: './release-payout-dialog.component.html'
})
export class ReleasePayoutDialogComponent implements OnInit, OnDestroy {
    protected releasePayoutFormGroup = new FormGroup({
        payoutCutOffDate: new FormControl<string | null>(null, [Validators.required]),
        confirmPayout: new FormControl('', [Validators.pattern('Payout')])
    });

    protected payoutCutOffDateErrorSignal = signal<string>('');
    protected confirmPayoutErrorSignal = signal<string>('');

    private destroy$ = new Subject<void>();

    constructor(public config: DialogConfig, public dialogRef: DialogRef<{ payoutCutOffDate: string }>) {}

    ngOnInit(): void {
        this.initializeErrorMessageListeners();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    public onClose(result?: string): void {
        if (result && this.releasePayoutFormGroup?.value) {
            const payoutCutOffDate = this.releasePayoutFormGroup.value.payoutCutOffDate;

            if (payoutCutOffDate) {
                this.dialogRef.close({ payoutCutOffDate });
                return;
            }
        }
        this.dialogRef.close();
    }

    protected getErrorMessage(control: string): void {
        const errors = Object.keys(this.releasePayoutFormGroup.get(control)?.errors || {});

        if (!errors?.length) {
            this.payoutCutOffDateErrorSignal.set('');
            this.confirmPayoutErrorSignal.set('');
            return;
        }

        const [error] = errors;

        if (error === 'required' && !this.releasePayoutFormGroup.get(control)?.touched) {
            if (control === 'payoutCutOffDate') this.payoutCutOffDateErrorSignal.set('required');
            if (control === 'confirmPayout') this.confirmPayoutErrorSignal.set('required');
        }

        if (error !== 'required' && this.releasePayoutFormGroup.get('confirmPayout').value !== 'Payout') {
            if (control === 'confirmPayout')
                this.confirmPayoutErrorSignal.set(
                    'seasons.season-details.dialogs.release-payout-dialog.errors.confirm-payout'
                );
        }
    }

    private initializeErrorMessageListeners(): void {
        this.releasePayoutFormGroup
            .get('payoutCutOffDate')
            ?.valueChanges.pipe(
                map(() => this.getErrorMessage('payoutCutOffDate')),
                takeUntil(this.destroy$)
            )
            .subscribe();

        this.releasePayoutFormGroup
            .get('confirmPayout')
            ?.valueChanges.pipe(
                map(() => this.getErrorMessage('confirmPayout')),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }
}
