<div class="flex items-center header-wrapper pt-2 border-b border-customBorder">
    <!-- top part -->
    <div>
        <div class="nav-part start cursor-pointer" [routerLink]="['/']">
            <div class="logo"><img [src]="'assets/images/sag-logo/sie-logo-white-rgb.svg'" alt="Siemens logo" /></div>
            <div class="logo"><img [src]="'assets/images/next-siemens-logo.svg'" alt="Next Siemens logo" /></div>
            <div class="app-name">GBR EMA Cloud</div>
        </div>
        <div class="nav-part end hide-mobile">
            <button class="nav-icon" [matMenuTriggerFor]="langMenu">
                <span class="icon-region"></span>
            </button>
            <mat-menu #langMenu="matMenu">
                <button
                    (click)="changeLang(langCode.EN)"
                    mat-menu-item
                    [class.active]="selectedLangSignal() === langCode.EN"
                >
                    {{ 'general.language.english' | translate }}
                </button>
                <button
                    (click)="changeLang(langCode.DE)"
                    mat-menu-item
                    [class.active]="selectedLangSignal() === langCode.DE"
                >
                    {{ 'general.language.german' | translate }}
                </button>
            </mat-menu>

            <app-user class="hide-mobile"></app-user>
        </div>
    </div>
</div>
