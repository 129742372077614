<sag-ui-button
    color="tertiary"
    [routerLink]="['/dashboard']"
    icon="chevron-left"
    class="-ml-4 mt-4"
    ariaLabel="Go to Dashboard"
>
    {{ 'general.back-to-dashboard' | translate }}
</sag-ui-button>

<div>
    <!-- Header -->
    <header class="bg-transparent h-1/6 mb-4 flex justify-between items-center pb-10 pt-5">
        <div class="flex flex-row w-1/4 justify-start">
            @if (seasonSignal(); as season) {
            <div class="pr-4">
                <h2 class="text-xl font-bold">{{ season.title }}</h2>
                <p class="text-darkGraySubtitleColor">
                    {{ season.startDate | date : 'dd.MM.yyyy' }} -
                    {{ season.endDate | date : 'dd.MM.yyyy' }}
                </p>
            </div>
            <p
                [style.backgroundColor]="seasonStateBackgroundColor[season.status]"
                class="h-fit text-black text-xs font-semibold px-3 mt-1 rounded-full flex justify-center items-center align-middle"
            >
                {{ season.status }}
            </p>
            } @else {
            <div class="animate-pulse">
                <div class="bg-cardBackgroundColor h-8 w-40 rounded-md"></div>
                <div class="bg-cardBackgroundColor h-4 w-20 rounded-md mt-2"></div>
            </div>
            }
        </div>

        <div class="flex flex-col gap-3">
            @if (seasonSignal(); as season) { @if(seasonHasPayoutApplicationsSignal()) {
            <sag-ui-button
                class="pr-4"
                color="secondary"
                icon="document"
                (click)="downloadPayoutFiles()"
                [loading]="isDownloading"
                ariaLabel="Download button"
            >
                {{ 'seasons.season-details.buttons.payout-files' | translate }}
            </sag-ui-button>
            } @if (season.status === seasonStatus.ACTIVE) {
            <sag-ui-button
                icon="chevron-right"
                (click)="releasePayout()"
                [disabled]="!isBudgetAvailableSignal() || !isApplicationsSelectedForPayoutSignal()"
                ariaLabel="Release payout"
                >{{ 'seasons.season-details.buttons.release-payout' | translate }}</sag-ui-button
            >
            }
            <sag-ui-button
                icon="chevron-right"
                (click)="closeSeason()"
                [disabled]="!hasSeasonApplicationPeriodEndedSignal() || isSeasonClosedSignal()"
                ariaLabel="close season"
                >{{ 'seasons.season-details.buttons.close-season' | translate }}</sag-ui-button
            >
            }
        </div>
    </header>

    <div>
        <sag-ui-tabview class="flex flex-col justify-between align-middle">
            <!-- Applications -->
            <sag-ui-tab
                value="dashboard"
                label="{{ 'seasons.season-details.tabs.application' | translate }}"
                (click)="activeTab = 'applications'"
                [active]="true"
            >
            </sag-ui-tab>
            <sag-ui-tab
                value="orders"
                label="{{ 'seasons.season-details.tabs.settings-and-weighting' | translate }}"
                (click)="activeTab = 'orders'"
            >
                <!-- Season Settings And Weighting -->
                <div>
                    <app-timeframe-card
                        title="{{ 'seasons.season-details.setting-cards.timeframe-card.title' | translate }}"
                        [startDate]="seasonSignal()?.startDate"
                        [endDate]="seasonSignal()?.endDate"
                        [applicationDeadlineDate]="seasonSignal()?.applicationDeadline"
                    />
                    <div class="flex flex-row space-x-4">
                        <div class="flex-1">
                            <app-budget-card
                                title="{{ 'seasons.season-details.setting-cards.budget-card.title' | translate }}"
                                subtitle="{{ 'seasons.season-details.setting-cards.budget-card.subtitle' | translate }}"
                                [totalBudget]="seasonSignal()?.totalBudget"
                                [payoutElements]="seasonSignal()?.payoutElements"
                            />
                        </div>
                        <div class="flex-1">
                            <app-social-criteria-card
                                title="{{
                                    'seasons.season-details.setting-cards.social-criteria-card.title' | translate
                                }}"
                                subtitle="{{
                                    'seasons.season-details.setting-cards.social-criteria-card.subtitle' | translate
                                }}"
                                [socialCriterias]="seasonSignal()?.eligibilityCriteria"
                            />
                        </div>
                        <div class="flex-1">
                            <app-salary-card
                                title="{{ 'seasons.season-details.setting-cards.salary-card.title' | translate }}"
                                subtitle="{{ 'seasons.season-details.setting-cards.salary-card.subtitle' | translate }}"
                                [salaryRangeItems]="seasonSignal()?.salaryRangeItems"
                            />
                        </div>
                    </div>
                </div>
            </sag-ui-tab>
        </sag-ui-tabview>
        @if (activeTab !== 'orders') {
        <div>
            <div
                class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[3fr_3fr_4fr_4fr] gap-2 my-8"
                [class.animate-pulse]="isLoading$ | async"
            >
                @if (financialOverviewSignal()) {
                <app-financial-card
                    [title]="'seasons.season-details.application-cards.total-applications.title' | translate"
                    [subtitle]="'seasons.season-details.application-cards.total-applications.subtitle' | translate"
                    [amount]="financialOverviewSignal()?.totalApplications"
                />
                <app-financial-card
                    [title]="'seasons.season-details.application-cards.selected-applications.title' | translate"
                    [subtitle]="'seasons.season-details.application-cards.selected-applications.subtitle' | translate"
                    [amount]="financialOverviewSignal()?.selectedApplications"
                    [ammountShouldBeGreen]="true"
                />
                <app-financial-card
                    [title]="'seasons.season-details.application-cards.total-budget.title' | translate"
                    [subtitle]="'seasons.season-details.application-cards.total-budget.subtitle' | translate"
                    [amount]="financialOverviewSignal()?.totalBudget"
                    [currency]="true"
                />
                <app-financial-card
                    [title]="'seasons.season-details.application-cards.available-budget.title' | translate"
                    [subtitle]="'seasons.season-details.application-cards.available-budget.subtitle' | translate"
                    [amount]="financialOverviewSignal()?.availableBudget"
                    [ammountShouldBeGreen]="true"
                    [currency]="true"
                />
                } @else {
                <div class="p-6 rounded-md w-full h-[156px] items-center align-middle bg-cardBackgroundColor"></div>
                <div class="p-6 rounded-md w-full h-[156px] items-center align-middle bg-cardBackgroundColor"></div>
                <div class="p-6 rounded-md w-full h-[156px] items-center align-middle bg-cardBackgroundColor"></div>
                <div class="p-6 rounded-md w-full h-[156px] items-center align-middle bg-cardBackgroundColor"></div>
                }
            </div>

            <hr class="border-separatorColor mb-8" />

            <!-- Applications Table -->

            <div class="flex justify-between mt-4">
                <h2 class="text-xl font-bold mb-4 mt-4">
                    {{ 'seasons.season-details.tabs.application' | translate }}
                </h2>
                @if (seasonSignal(); as season) {
                <sag-ui-button
                    color="secondary"
                    (click)="autoSelectApplications()"
                    [disabled]="isAutoSelectDisabledSignal()"
                    ariaLabel="auto select applications"
                    >{{ 'seasons.season-details.buttons.auto-select-application' | translate }}</sag-ui-button
                >
                }
            </div>

            <div class="flex items-center justify-end mb-4">
                <!-- Search -->
                <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 20rem">
                    <sag-ui-input
                        placeholder="{{ 'seasons.season-details.search' | translate }}"
                        [formControl]="applicationSearchControl"
                    ></sag-ui-input>
                </div>
            </div>

            @if (isLoadingApplications$ | async) {
            <div class="flex items-center justify-center h-56">
                <sag-ui-spinner size="md"></sag-ui-spinner>
            </div>
            } @else {
            <!-- Applications Table -->
            @if(applicationsSignal()?.length !== 0) {
            <app-season-applications-table [columnNames]="columnNames" />
            } @else {
            <p class="flex items-center justify-center text-lg">
                {{ 'seasons.season-details.table.no-applications-message' | translate }}
            </p>
            } }
        </div>
        }
    </div>
</div>
