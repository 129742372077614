<div class="p-6 pt-4">
    <form class="mb-6 max-w-[400px] flex flex-col gap-4" [formGroup]="autoselectFormGroup">
        <h3 class="text-xl mb-2">
            {{ 'seasons.season-overview.dialogs.autoselect-applications-dialog.title' | translate }}
        </h3>
        <p class="text-base mb-4">
            {{ 'seasons.season-overview.dialogs.autoselect-applications-dialog.description' | translate }}
        </p>
        <sag-ui-input
            type="date"
            formControlName="applicationCutOffDate"
            [label]="
                'seasons.season-overview.dialogs.autoselect-applications-dialog.application-cut-off-date' | translate
                "
            placeholder="01.01.2026"
            [required]="true"
            [error]="applicationCutOffDateErrorSignal() | translate"
        ></sag-ui-input>
        <sag-ui-input
            type="number"
            [label]="'seasons.season-overview.dialogs.autoselect-applications-dialog.percentage-label' | translate"
            formControlName="percentage"
            [required]="true"
            [error]="percentageErrorSignal() | translate"
        ></sag-ui-input>
    </form>
    <div class="flex justify-end items-center gap-2 mt-8">
        <sag-ui-button color="secondary" icon="chevron-left" (click)="onClose()" ariaLabel="Close">
            {{ 'seasons.season-overview.dialogs.autoselect-applications-dialog.cancel' | translate }}
        </sag-ui-button>
        <sag-ui-button icon="chevron-right" [disabled]="!autoselectFormGroup.valid" (click)="onClose('confirm')" ariaLabel="Confirm close">
            {{ 'seasons.season-overview.dialogs.autoselect-applications-dialog.confirm' | translate }}
        </sag-ui-button>
    </div>
</div>
