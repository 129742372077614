<div class="flex flex-col justify-center items-center align-middle w-full h-screen p-8">
    <div class="p-8">
        <svg width="84" height="80" viewBox="0 0 84 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.2498 79.375L22.1248 67.375L8.62476 64.375L9.93726 50.5L0.749756 40L9.93726 29.5L8.62476 15.625L22.1248 12.625L29.2498 0.625L41.9998 6.0625L54.7498 0.625L61.8748 12.625L75.3748 15.625L74.0623 29.5L83.2498 40L74.0623 50.5L75.3748 64.375L61.8748 67.375L54.7498 79.375L41.9998 73.9375L29.2498 79.375ZM32.4373 69.8125L41.9998 65.6875L51.7498 69.8125L56.9998 60.8125L67.3123 58.375L66.3748 47.875L73.3123 40L66.3748 31.9375L67.3123 21.4375L56.9998 19.1875L51.5623 10.1875L41.9998 14.3125L32.2498 10.1875L26.9998 19.1875L16.6873 21.4375L17.6248 31.9375L10.6873 40L17.6248 47.875L16.6873 58.5625L26.9998 60.8125L32.4373 69.8125ZM38.0623 53.3125L59.2498 32.125L53.9998 26.6875L38.0623 42.625L29.9998 34.75L24.7498 40L38.0623 53.3125Z"
                fill="#00FFB9"
            />
        </svg>
    </div>
    <h2 class="p-2">{{ 'seasons.season-creation.success.title' | translate }}</h2>
    <p>{{ 'seasons.season-creation.success.text-one' | translate }}</p>
    <p>
        {{ 'seasons.season-creation.success.text-two' | translate }}
        <span class="cursor-pointer text-siemensGreen" (click)="handleNavigateToSeasonsOverview()">{{
            'seasons.season-creation.success.season-overview-label' | translate
        }}</span
        >.
    </p>
    <sag-ui-button icon="home" class="p-4" (click)="onBackToHome()" ariaLabel="Home button">{{
        'seasons.season-creation.success.button-label' | translate
    }}</sag-ui-button>
</div>
