<div class="relative overflow-x-auto w-full max-h-[60vh] mb-8" #table>
    <table class="border-separate w-full border-spacing-y-1">
        <thead>
            <tr>
                @for (column of columnNames; track $index) {
                <th class="bg-[var(--bgColorPrimary)] font-normal">
                    <div
                        class="flex items-center justify-start px-0 py-2.5 whitespace-nowrap min-w-8 gap-2.5 mr-2.5 text-xs"
                    >
                        <span>{{ column | translate }}</span>
                    </div>
                </th>
                }
            </tr>
        </thead>

        <tbody>
            @for (application of applications$ | async; track $index) {
            <tr class="h-14 bg-[var(--bgColorSecondary)] color-[var(--textColor)]">
                <td class="rounded-tl-[10px] rounded-bl-[10px] p-2">
                    <div>{{ application?.employeeGid }}</div>
                </td>
                <td class="text-[#00CCCC]">
                    @if (application?.employee){
                    <div (click)="openApplication(application)" class="cursor-pointer">
                        {{ application.employee.firstName }} {{ application.employee.lastName }}
                    </div>
                    } @else {
                    <div>No name provided</div>
                    }
                </td>
                <td class="px-2">
                    <span
                        [style.backgroundColor]="statusColor[application.status]"
                        class="text-xs text-[#000028] font-bold rounded-full px-4 py-1"
                        >{{ 'seasons.season-details.application-status.' + application.status | translate }}</span
                    >
                </td>
                <td>
                    <div class="flex justify-end">
                        {{ application.salaryRange.lowerBound }} - {{ application.salaryRange.upperBound }} €
                    </div>
                </td>
                <td>
                    <div class="flex justify-center">
                        {{ application.children?.length || 0 }}
                    </div>
                </td>
                <td>
                    <div class="flex items-center justify-center">
                        @if (application.spouse) {
                        <span class="icon-checkmark-medium text-green-600"></span>
                        } @else {
                        <span class="icon-close text-red-600"></span>
                        }
                    </div>
                </td>
                <td>
                    <div class="flex justify-center">{{ application.lastYearPayout.toFormat() }}</div>
                </td>
                <td>
                    <div class="flex justify-center">
                        {{ application.evaluationPoints }}
                    </div>
                </td>
                <td>
                    <div class="flex justify-center">{{ application.potentialPayout.toFormat() }}</div>
                </td>
                <td>
                    <div class="flex items-center justify-between gap-3 px-4">
                        <span
                            class="icon-chat-1 cursor-pointer xl:hidden block"
                            [style]="application.comment ? 'color: #00CCCC;' : 'color: #a8a8a8;'"
                            sag-ui-tooltip
                            [tooltip]="application.comment"
                        ></span>
                        <p
                            class="truncate max-w-[150px] cursor-pointer xl:block hidden"
                            sag-ui-tooltip
                            [tooltip]="application.comment"
                        >
                            {{ application.comment ?? '' }}
                        </p>
                        <span
                            class="icon-edit cursor-pointer text-[#00CCCC] hover:text-[#00FFB9]"
                            (click)="openCommentDialog(application)"
                        ></span>
                    </div>
                </td>
                <td class="rounded-tr-[10px] rounded-br-[10px]">
                    <div class="flex items-center justify-center">
                        @if (season$ | async; as season) {
                        <sag-ui-checkbox
                            [disabled]="season.status !== seasonStatus.PAYOUT_REVIEW"
                            [checked]="application.isSelectedForPayout"
                            (change)="toggleSelectedForPayout(application)"
                        />
                        }
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
