/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'packages/frontend-admin/src/environments/environment';
import { HttpHelperService } from '../../shared/services/http-helper.service';
import { FinancialOverviewResponse } from '../models/season-financial.model';
import { SeasonCreate } from '../models/season-create-dto.model';
import { Dinero } from 'dinero.js';
import { SeasonOperations } from '../operations/season.operations';
import { AttachmentOperations } from '../operations/attachment.operations';
import { SignedUrl } from '../../shared/interfaces/SignedUrl';
import { SnackbarService } from '@siemens/component-lib';
import { SnackbarPosition, SnackbarType } from '../../shared/enums/snackbar.enum';
import { SnackbarConfig } from '../../shared/configs/snackbar.config';
import { TranslateService } from '@ngx-translate/core';
import { Season, Page, UserApplication, SeasonWithUpdateBy, AutoSelectApplications } from 'interfaces/app';

@Injectable({
    providedIn: 'root'
})
export class SeasonService {
    constructor(
        private http: HttpClient,
        private httpHelpersService: HttpHelperService,
        private translate: TranslateService,
        private snackbarService: SnackbarService
    ) {}

    public createSeason(seasonData: SeasonCreate): Observable<Season<Dinero>> {
        delete seasonData.id;
        return this.http
            .post<Season<number>>(
                `${environment.apiUrl}/v1/seasons`,
                SeasonOperations.mapSeasonCreateFromDineroToNumber(seasonData)
            )
            .pipe(map(season => SeasonOperations.mapSeasonFromNumberToDinero(season)));
    }

    public updateSeason(seasonData: SeasonCreate): Observable<Season<Dinero>> {
        const seasonId = seasonData.id;
        delete seasonData.id;
        return this.http
            .patch<Season<number>>(
                `${environment.apiUrl}/v1/seasons/${seasonId}`,
                SeasonOperations.mapSeasonCreateFromDineroToNumber(seasonData)
            )
            .pipe(map(season => SeasonOperations.mapSeasonFromNumberToDinero(season)));
    }

    public getSeasonById(seasonId: string): Observable<Season> {
        return this.http.get<Season<number>>(`${environment.apiUrl}/v1/seasons/${seasonId}`).pipe(
            map(season => SeasonOperations.mapSeasonFromNumberToDinero(season)),
            this.httpHelpersService.handleError('Cannot fetch Season data')
        );
    }

    public getSeasonApplicationsById(
        seasonId: string,
        page: number,
        pageSize: number,
        gid?: string
    ): Observable<Page<UserApplication>> {
        const params = {
            page,
            pageSize,
            orderBy: 'evaluationPoints',
            sortOrder: 'DESC'
        };

        if (gid) {
            params['employeeGid'] = gid.toUpperCase();
        }

        return this.http
            .get<Page<UserApplication<number>>>(`${environment.apiUrl}/v1/seasons/${seasonId}/applications`, {
                params
            })
            .pipe(
                map(applications => ({
                    ...applications,
                    items: applications.items.map(SeasonOperations.mapUserApplicationFromNumberToDinero)
                })),
                this.httpHelpersService.handleError('Cannot fetch Season Applications data')
            );
    }

    public getSeasonFinancialOverviewBySeasonId(seasonId: string): Observable<FinancialOverviewResponse> {
        return this.http
            .get<FinancialOverviewResponse<number>>(`${environment.apiUrl}/v1/seasons/${seasonId}/financial-overview`)
            .pipe(
                map(financialOverview =>
                    SeasonOperations.mapFinancialOverviewResponseFromNumberToDinero(financialOverview)
                ),
                this.httpHelpersService.handleError('Cannot fetch Season Financial Overview data')
            );
    }

    public getSeasons(
        page: number,
        pageSize: number,
        searchTerm: string | undefined
    ): Observable<Page<SeasonWithUpdateBy>> {
        const params = {
            page,
            pageSize,
            orderBy: 'startDate'
        };
        if (searchTerm?.length) {
            params['searchTerm'] = searchTerm;
        }
        return this.http.get<Page<SeasonWithUpdateBy>>(`${environment.apiUrl}/v1/seasons`, {
            params
        });
    }

    public deleteSeason(id: string): Observable<void> {
        return this.http
            .delete<void>(`${environment.apiUrl}/v1/seasons/${id}`)
            .pipe(this.httpHelpersService.handleError(`Could not delete season '${id}'.`));
    }

    public activateSeason(id: string): Observable<void> {
        return this.http
            .post<void>(`${environment.apiUrl}/v1/seasons/${id}/set-active`, {})
            .pipe(this.httpHelpersService.handleError(`Could not set season '${id}' to active.`));
    }

    public closeSeason(id: string): Observable<void> {
        return this.http.post<void>(`${environment.apiUrl}/v1/seasons/${id}/set-closed`, {}).pipe(
            this.httpHelpersService.handleError(`Could not close the season '${id}'.`),
            tap(() =>
                this.snackbarService.open({
                    title: this.translate.instant('seasons.season-closing.snackbar.title'),
                    description: this.translate.instant('seasons.season-closing.snackbar.success'),
                    type: SnackbarType.SUCCESS,
                    duration: SnackbarConfig.duration,
                    position: SnackbarPosition.BOTTOM
                })
            )
        );
    }

    public autoSelectApplications(seasonId: string, autoSelectConfig: AutoSelectApplications): Observable<void> {
        return this.http
            .post<void>(`${environment.apiUrl}/v1/seasons/${seasonId}/auto-select-payout`, autoSelectConfig)
            .pipe(this.httpHelpersService.handleError('Could not auto select applications.'));
    }

    public releasePayout(seasonId: string, payoutCutOffDate: Date): Observable<void> {
        return this.http
            .patch<void>(`${environment.apiUrl}/v1/seasons/${seasonId}/send-to-payout`, payoutCutOffDate)
            .pipe(
                this.httpHelpersService.handleError('Could not release payout.'),
                tap(() =>
                    this.snackbarService.open({
                        title: this.translate.instant('seasons.season-closing.snackbar.title'),
                        description: this.translate.instant('seasons.season-closing.snackbar.success'),
                        type: SnackbarType.SUCCESS,
                        duration: SnackbarConfig.duration,
                        position: SnackbarPosition.BOTTOM
                    })
                )
            );
    }

    public downloadPayoutFiles(seasonId: string, name: string): Observable<SignedUrl> {
        return this.http.get<SignedUrl>(`${environment.apiUrl}/v1/seasons/${seasonId}/payout-files/download`).pipe(
            this.httpHelpersService.handleError(`Could not get signed URL for download`),
            tap(({ signedUrl }) => AttachmentOperations.downloadFile(signedUrl, name))
        );
    }
}
