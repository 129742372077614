<div class="user">
    <div class="user__header">
        <div class="title">
            <h2 class="user__headline">{{ 'user-management.title' | translate }}</h2>
        </div>
        <div class="user__header__row">
            <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 24rem">
                <sag-ui-input
                    placeholder="{{ 'user-management.search' | translate }}"
                    [formControl]="searchControl"
                ></sag-ui-input>
            </div>

            <sag-ui-button (click)="openAddUserDialog($event)" color="primary" ariaLabel="Open add user dialog">
                {{ 'user-management.buttons.add' | translate }}
            </sag-ui-button>
        </div>
    </div>
    <div class="user__content">
        <ng-container>
            <div *ngIf="isLoading || (users?.length ?? 0) > 0; else no_users_found" class="user__table">
                <app-table
                    [columns]="columns"
                    [data]="users"
                    [loading]="isLoading"
                    [pagination]="true"
                    [total]="total"
                    [pageSizeOptions]="pageSizeOptions"
                    [page]="page"
                    (pageChange)="onPageChange($event)"
                ></app-table>
            </div>
        </ng-container>

        <ng-template #no_users_found>
            <div class="user__table__message">
                <p>{{ 'user-management.no-results' | translate }}</p>
            </div>
        </ng-template>
    </div>
</div>
