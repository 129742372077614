import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EligibilityCriterion } from 'interfaces/app';

@Component({
    selector: 'app-social-criteria-card',
    standalone: true,
    templateUrl: './social-criteria-card.component.html',
    imports: [CommonModule, TranslateModule]
})
export class SocialCriteriaCardComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() socialCriterias: EligibilityCriterion[];
}
