<div class="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
    <div class="p-6 pt-4 bg-[var(--bgColorSecondary)]">
        <h3 class="text-xl mb-2">{{ 'workers-council-groups.select-delegate-dialog.title' | translate }}</h3>
        <p class="text-base mb-4">{{ 'workers-council-groups.select-delegate-dialog.description' | translate }}</p>
        <div>
            <sag-ui-remote-autocomplete
                [options]="(workersCouncilUsers$ | async) ?? []"
                [searchFn$]="searchFn.bind(this)"
                [compareWith]="compareFn"
                [formControl]="selectControl"
                [invalid]="selectControl.invalid"
                [error]="selectControl.errors?.['invalid']"
                label="{{ 'workers-council-groups.select-delegate-dialog.label' | translate }}"
            ></sag-ui-remote-autocomplete>
        </div>
        <div class="flex justify-end items-center gap-2 mt-8">
            <sag-ui-button color="secondary" (click)="dialogRef.close()" ariaLabel="cancel">
                {{ 'workers-council-groups.select-delegate-dialog.close' | translate }}
            </sag-ui-button>
            <sag-ui-button
                (click)="addSelectedUser()"
                ariaLabel="continue"
                icon="plus-large"
                [disabled]="selectControl.invalid || isLoading"
            >
                {{ 'workers-council-groups.select-delegate-dialog.add' | translate }}
            </sag-ui-button>
        </div>
    </div>
</div>
