/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'packages/frontend-admin/src/environments/environment';
import { HttpHelperService } from '../../shared/services/http-helper.service';
import { Page, WorkersCouncilGroup, PatchWorkersCouncilGroup } from 'interfaces/app';

@Injectable({
    providedIn: 'root'
})
export class WorkersCouncilGroupService {
    constructor(private http: HttpClient, private httpHelpersService: HttpHelperService) {}

    public getWorkersCouncilGroups(page: number, pageSize: number): Observable<Page<WorkersCouncilGroup>> {
        const params = {
            page,
            pageSize
        };

        return this.http
            .get<Page<WorkersCouncilGroup>>(`${environment.apiUrl}/v1/workers-council-groups`, { params })
            .pipe(this.httpHelpersService.handleError());
    }

    public getWorkersCouncilGroupById(workersCouncilGroupId: string): Observable<WorkersCouncilGroup> {
        return this.http
            .get<WorkersCouncilGroup>(`${environment.apiUrl}/v1/workers-council-groups/${workersCouncilGroupId}`)
            .pipe(this.httpHelpersService.handleError());
    }

    public updateWorkersCouncilGroup(
        workersCouncilGroupId: string,
        workersCouncilGroup: PatchWorkersCouncilGroup
    ): Observable<WorkersCouncilGroup> {
        return this.http
            .patch<WorkersCouncilGroup>(
                `${environment.apiUrl}/v1/workers-council-groups/${workersCouncilGroupId}`,
                workersCouncilGroup
            )
            .pipe(this.httpHelpersService.handleError());
    }
}
