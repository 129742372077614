import { ScdPickerBaseValue } from '@siemens/component-lib';
import { User, WorkersCouncilGroup } from 'interfaces/app';

export class WorkersCouncilGroupOperations {
    public static addDelegate(
        id: string,
        groups: WorkersCouncilGroup[],
        user: ScdPickerBaseValue
    ): { groups: WorkersCouncilGroup[]; delegateGIDs: string[] } {
        const group = groups.find(group => group.id === id);

        if (!group) {
            return { groups, delegateGIDs: [] };
        }

        const userAlreadyDelegate = group?.delegates?.some(delegate => delegate.gid === user.gid);

        if (userAlreadyDelegate) {
            return { groups, delegateGIDs: group.delegates.map(delegate => delegate.gid) };
        }

        const newDelegate = {
            firstName: user.firstName,
            lastName: user.lastName,
            gid: user.gid
        } as User;

        group.delegates = group.delegates ? [...group.delegates, newDelegate] : [newDelegate];

        return {
            groups,
            delegateGIDs: group.delegates ? group.delegates.map(delegate => delegate.gid) : [user.gid]
        };
    }

    public static removeDelegate(
        id: string,
        groups: WorkersCouncilGroup[],
        gid: string
    ): { groups: WorkersCouncilGroup[]; delegateGIDs: string[] } {
        const group = groups.find(group => group.id === id);

        if (!group) {
            return { groups, delegateGIDs: [] };
        }

        return {
            groups: groups.map(group => {
                if (group.id === id) {
                    return {
                        ...group,
                        delegates: group.delegates ? group.delegates.filter(delegate => delegate.gid !== gid) : []
                    };
                }
                return group;
            }),
            delegateGIDs: group.delegates
                ? group.delegates.map(delegate => delegate.gid).filter(delegateGid => delegateGid !== gid)
                : []
        };
    }
}
