<div class="p-6 rounded-md w-full items-center align-middle bg-cardBackgroundColor">
    <!-- Title -->
    <p class="text-sm font-bold mb-1 truncate">{{ title }}</p>

    <!-- Subtitle -->
    <p class="mb-4 text-sm text-[#9999A9] truncate">{{ subtitle }}</p>

    <!-- Valuea -->
    <h1 class="font-bold" [ngClass]="{ 'text-subHeadingAction': ammountShouldBeGreen }">
        {{ currency ? (amount | customCurrency) : amount }}
    </h1>
</div>
