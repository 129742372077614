import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogConfig, DialogRef } from '@siemens/component-lib';
import { SharedModule } from '../../../../shared/shared.module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-auto-select-applications-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
    templateUrl: './auto-select-applications-dialog.component.html'
})
export class AutoSelectApplicationsDialogComponent implements OnInit, OnDestroy {
    protected autoselectFormGroup: FormGroup = new FormGroup({
        applicationCutOffDate: new FormControl<string | null>(null, [Validators.required]),
        percentage: new FormControl<number | null>(null, [Validators.required, Validators.min(1), Validators.max(100)])
    });

    protected applicationCutOffDateErrorSignal = signal<string>('');
    protected percentageErrorSignal = signal<string>('');

    private destroy$ = new Subject<void>();

    constructor(
        public config: DialogConfig,
        public dialogRef: DialogRef<{ applicationCutOffDate: string; percentage: number }>
    ) {}

    ngOnInit(): void {
        this.initializeErrorMessageListeners();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    public onClose(result?: string): void {
        if (result && this.autoselectFormGroup?.value) {
            const { applicationCutOffDate, percentage } = this.autoselectFormGroup.value;

            if (applicationCutOffDate && percentage !== undefined) {
                this.dialogRef.close({ applicationCutOffDate, percentage: parseInt(percentage) });
                return;
            }
        }
        this.dialogRef.close();
    }

    protected getErrorMessage(control: string): void {
        const errors = Object.keys(this.autoselectFormGroup.get(control)?.errors || {});

        if (!errors?.length) {
            this.applicationCutOffDateErrorSignal.set('');
            this.percentageErrorSignal.set('');
            return;
        }

        const [error] = errors;

        if (error === 'required' && !this.autoselectFormGroup.get(control)?.touched) {
            if (control === 'applicationCutOffDate') this.applicationCutOffDateErrorSignal.set('required');
            if (control === 'percentage') this.percentageErrorSignal.set('required');
        }

        if (error !== 'required') {
            if (control === 'percentage')
                this.percentageErrorSignal.set('seasons.season-overview.dialogs.errors.percentage-' + errors[0]);
        }
    }

    private initializeErrorMessageListeners(): void {
        this.autoselectFormGroup
            .get('applicationCutOffDate')
            ?.valueChanges.pipe(
                map(() => this.getErrorMessage('applicationCutOffDate')),
                takeUntil(this.destroy$)
            )
            .subscribe();

        this.autoselectFormGroup
            .get('percentage')
            ?.valueChanges.pipe(
                map(() => this.getErrorMessage('percentage')),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }
}
