import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { SeasonService } from '../services/season.service';

export const seasonStatusGuard: CanActivateFn = route => {
    const seasonService = inject(SeasonService);
    const router = inject(Router);

    const seasonId = route.paramMap.get('seasonId');
    if (!seasonId) {
        router.navigate(['/seasons']);
        return of(false);
    }

    return seasonService.getSeasonById(seasonId).pipe(
        map(season => {
            if (season.status === 'Closed') {
                router.navigate(['/seasons']);
                return false;
            }
            return true;
        }),
        catchError(() => {
            router.navigate(['/seasons']);
            return of(false);
        })
    );
};
