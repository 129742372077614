import { Component, computed, HostListener, model, OnDestroy, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, select } from '@ngxs/store';
import { Router, RouterModule } from '@angular/router';
import { SeasonState } from '../../../state/season.state';
import { ActivateSeason, DeleteSeason, LazyLoadSeasons, LoadSeasons } from '../../../state/season.action';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
    ButtonModule,
    DialogModule,
    DialogService,
    InputModule,
    MenuModule,
    SpinnerModule,
    TooltipModule
} from '@siemens/component-lib';
import { scrollOverAmount } from '../../../helpers/tiny-helpers';
import { BasicDialogComponent } from '../../../../shared/components/basic-dialog/basic-dialog.component';
import { filter, Subject, takeUntil, tap } from 'rxjs';
import { SharedModule } from '../../../../shared/shared.module';
import { UserState } from '../../../../auth/state/user.state';
import { SeasonWithUpdateBy } from 'interfaces/app';
import { SeasonStatus } from 'interfaces/enums';

@Component({
    selector: 'app-season-overview',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ButtonModule,
        SpinnerModule,
        InputModule,
        MenuModule,
        DialogModule,
        SharedModule,
        TooltipModule
    ],
    templateUrl: './seasons-overview.component.html',
    styleUrl: './seasons-overview.component.scss'
})
export class SeasonsOverviewComponent implements OnDestroy {
    private destroy$ = new Subject<void>();
    public seasonStatus = SeasonStatus;
    public readonly columnNames = computed(() => {
        const columnNames = [
            'seasons.season-overview.table.title',
            'seasons.season-overview.table.creation-date',
            'seasons.season-overview.table.date-range',
            'seasons.season-overview.table.state',
            'seasons.season-overview.table.updated-by',
            'seasons.season-overview.table.updated-at'
        ];
        // If the user is an admin, add an additional column for the options button
        return !this.isAdmin() ? columnNames : [...columnNames, ''];
    });
    protected readonly seasonStateBackgroundColor = {
        [SeasonStatus.ACTIVE]: '#ffffff',
        [SeasonStatus.DRAFT]: '#FFD732',
        [SeasonStatus.CLOSED]: '#9999A9'
    };
    protected readonly draftSeasonStatus = SeasonStatus.DRAFT;
    protected readonly activeSeasonStatus = SeasonStatus.ACTIVE;
    protected readonly closedSeasonStatus = SeasonStatus.CLOSED;
    protected readonly seasons: Signal<SeasonWithUpdateBy[]> = select(SeasonState.getSeasons);
    protected readonly isLoading: Signal<boolean> = select(SeasonState.isLoadingSeasons);
    protected readonly isInitalLoading: Signal<boolean> = select(SeasonState.isSeasonsInitalLoading);
    protected readonly allSeasonsLoaded: Signal<boolean> = select(SeasonState.allSeasonsLoaded);
    protected readonly isAdmin: Signal<boolean> = select(UserState.isAdmin);
    protected readonly isProcessManager: Signal<boolean> = select(UserState.isProcessManager);
    protected readonly filteredSeasons: Signal<SeasonWithUpdateBy[]> = computed(() =>
        this.seasons().filter(season =>
            [season.id, season.title, season.updatedBy, season.startDate, season.endDate, season.status]
                .map(s => `${s}`.toLocaleLowerCase())
                .join()
                .includes(this.searchValue().toLocaleLowerCase())
        )
    );

    protected searchValue = model('');

    constructor(
        private readonly store: Store,
        private readonly dialog: DialogService,
        private readonly router: Router
    ) {
        this.store.dispatch(new LoadSeasons());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: any): void {
        if (!scrollOverAmount(event) || this.isLoading() || this.allSeasonsLoaded()) {
            return;
        }
        this.store.dispatch(new LazyLoadSeasons());
    }

    public handleClickOnSeason(seasonId: string): void {
        // Navigate to Season Details Page
        this.router.navigate(['/season-details', seasonId]);
    }

    public openDeleteDialog(seasonId: string): void {
        this.dialog
            .open(BasicDialogComponent, {
                width: '800px',
                backgroundColor: '#23233C',
                data: {
                    title: 'seasons.season-overview.dialogs.delete-dialog.title',
                    message: 'seasons.season-overview.dialogs.delete-dialog.text',
                    reject: 'seasons.season-overview.dialogs.delete-dialog.reject',
                    confirm: 'seasons.season-overview.dialogs.delete-dialog.accept'
                }
            })
            .afterClosed.pipe(
                takeUntil(this.destroy$),
                filter(confirmed => !!confirmed),
                tap(() => this.store.dispatch(new DeleteSeason(seasonId)))
            )
            .subscribe();
    }

    public openSetActiveDialog(seasonId: string): void {
        this.dialog
            .open(BasicDialogComponent, {
                width: '800px',
                backgroundColor: '#23233C',
                data: {
                    title: 'seasons.season-overview.dialogs.set-active-dialog.title',
                    message: 'seasons.season-overview.dialogs.set-active-dialog.text',
                    reject: 'seasons.season-overview.dialogs.set-active-dialog.reject',
                    confirm: 'seasons.season-overview.dialogs.set-active-dialog.accept'
                }
            })
            .afterClosed.pipe(
                takeUntil(this.destroy$),
                filter(confirmed => !!confirmed),
                tap(() => {
                    this.store.dispatch(new ActivateSeason(seasonId));
                    this.store.dispatch(new LoadSeasons());
                })
            )
            .subscribe();
    }

    public editSeason(seasonId: string): void {
        this.router.navigate(['/season/' + seasonId]);
    }
}
